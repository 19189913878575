export default {
  displaySuccessToast(context, message = null) {
    if (!message) {
      message = "Erfolgreich gespeichert";
    }

    context.$buefy.toast.open({
      message: message,
      type: "is-success",
    });
  },
  displayErrorToast(context, error) {
    let message = "Ein Fehler ist aufgetreten";

    if (error.response && error.response.data && error.response.data.reason) {
      message = "Fehler: " + error.response.data.reason;
    }

    if (error.response.status === 500) {
      message = "Ein unerwarteter Fehler ist aufgetreten.";
    }

    context.$buefy.toast.open({
      message: message,
      type: "is-danger",
    });
  },
  createEuroStringFromCents(cents) {
    return this.createAmountStringFromCents(cents) + " €";
  },
  createAmountStringFromCents(cents) {
    return this.createAmountStringFromEuro(cents / 100.0);
  },
  createAmountStringFromEuro(euro) {
    // 123456 => "123.456,00"
    return euro
      .toFixed(2) // add 2 zeros at the end
      .replace(".", ",") // change decimal point to comma
      .replace(/\B(?=(\d{3})+(?!\d))/g, "."); // place a dot every thousand group
  },
  createNumberFromAmountString(amount) {
    return Number(amount.replace(".", "").replace(",", ".")) * 100;
  },
  createAmountStringFromNumber(amount) {
    return new Intl.NumberFormat("de-DE", {
      minimumFractionDigits: 1,
      maximumFractionDigits: 2,
    }).format(amount);
  },
  createIntegerStringFromNumber(amount) {
    return new Intl.NumberFormat("de-DE", {
      minimumIntegerDigits: 1,
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    }).format(amount);
  },
};