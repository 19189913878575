import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store/index'

Vue.use(VueRouter)

const routes = [
    {
        path     : '/',
        name     : 'home',
        title    : 'ELGA App',
        component: () => import(/* webpackChunkName: "home" */ '@/components/HomeComponent'),
        meta     : {
            auth: true,
        },
        redirect : '/dash',
        children : [
            {
                path     : '/dash',
                name     : 'dash',
                component: () => import(/* webpackChunkName: "dash" */ '@/components/DashComponent')
            },
            {
                path     : '/validation',
                name     : 'validation',
                component: () => import(/* webpackChunkName: "validation" */ '@/components/ValidationComponent')
            },
            {
                path     : '/core-data-missing',
                name     : 'core-data-missing',
                component: () => import(/* webpackChunkName: "core-data-missing" */ '@/components/CoreDataMissingComponent')
            },
            {
                path     : '/assignment-of-rights-missing',
                name     : 'assignment-of-rights-missing',
                props    : true,
                component: () => import(/* webpackChunkName: "assignment-of-rights-missing" */ '@/components/AssignmentOfRightsMissingComponent')
            },
            {
                path     : '/actor-performance-check',
                name     : 'actor-performance-check',
                props    : true,
                component: () => import(/* webpackChunkName: "assignment-of-rights-missing" */ '@/components/ActorPerformanceCheck')
            },
            {
                path     : '/scheduling/:scheduling_id',
                name     : 'scheduling',
                props    : route => ({
                    scheduling_id: route.params.scheduling_id
                }),
                component: () => import(/* webpackChunkName: "scheduling" */ '@/components/SchedulingEditComponent')
            },
            {
                path     : '/scheduling/:scheduling_id/submit',
                name     : 'scheduling-submit',
                props    : (route) => {
                    return route.params
                },
                component: () => import(/* webpackChunkName: "scheduling-submit" */ '@/components/SchedulingSubmitComponent')
            }
        ]
    },

    {
        name     : 'Noauth',
        title    : 'ELGA App',
        path     : '/',
        component: () => import(/* webpackChunkName: "home" */ '@/components/DefaultPageComponent'),
        children : [
            {
                path     : '/login',
                name     : 'login',
                title    : 'ELGA App',
                component: () => import(/* webpackChunkName: "login-page" */ '@/components/LoginPage')
            },
            {
                path     : '/register',
                name     : 'register',
                props    : route => ({companySlug: route.params.companySlug}),
                component: () => import(/* webpackChunkName: "register-page" */ '@/components/RegisterPage')
            },
            {
                path     : '/password-reset-request',
                name     : 'password-reset-request',
                component: () => import(/* webpackChunkName: "password-reset-request-page" */ '@/components/PasswordResetRequestPage')
            },
            {
                path     : '/password-reset',
                name     : 'password-reset',
                props    : route => ({token: route.query.token}),
                component: () => import(/* webpackChunkName: "password-reset-page" */ '@/components/PasswordResetPage')
            },
            {
                path     : '/loading',
                name     : 'loading',
                component: () => import(/* webpackChunkName: "loading-page" */ '@/components/LoadingPage')
            },
            {
                path     : '/firma/:companySlug',
                name     : 'company-login',
                props    : route => ({
                    companySlug: route.params.companySlug
                }),
                component: () => import(/* webpackChunkName: "company-login-page" */ '@/components/CompanyLoginPage')
            },
            {
                path     : '/firma/:companySlug/:token',
                name     : 'company-token-login',
                props    : route => ({
                    companySlug: route.params.companySlug,
                    token: route.params.token,
                }),
                component: () => import(/* webpackChunkName: "company-login-page" */ '@/components/CompanyLoginPage')
            },
            {
                path     : '/logout',
                name     : 'logout',
                component: () => import(/* webpackChunkName: "logout-page" */ '@/components/LogoutPage')
            },
        ]
    }
]

// (to, from, savedPosition) param list
const scrollBehavior = () => {
    return {x: 0, y: 0}
}

const router = new VueRouter({
                                 mode          : 'history',
                                 base          : process.env.BASE_URL,
                                 routes        : routes,
                                 scrollBehavior: scrollBehavior,
                             })

router.beforeEach((to, from, next) => {
    // instead of having to check every route record with
    // to.matched.some(record => record.meta.requiresAuth)
    if (to.name !== 'validation' && router.app.$auth.user() && !router.app.$auth.user().validated) {
        console.log("User not validated! Navigating to validation page.")
        next({name: 'validation'})
        return
    }

    if (to.name !== 'loading' && to.name !== 'password-reset' && !Vue.auth.ready()) {
        Vue.auth.load().then(() => {
            router.push(to.path)
        }).catch(error => {
            console.log("Auth load error", error)
        })
        next({name: 'loading'})
        return
    }


    if (to.name === 'dash' &&
        store.state.dubbingActor &&
        !store.state.dubbingActor.hasValidCoreDataSet &&
        !store.state.coreDataSubmission) {
        next('/core-data-missing')
        return
    }
    // Always scroll to top
    window.scrollTo(0, 0)

    next();
})


export default router